.terms {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.terms h1 {
  margin-top: 6pt;
  margin-right: 0in;
  margin-bottom: 6pt;
  margin-left: 0in;
  text-align: justify;
  text-indent: 0in;
  font-size: 1rem;
  font-family: "Calibri", sans-serif;
  font-weight: normal;
}

.terms h2 {
  margin-top: 6pt;
  margin-right: 0in;
  margin-bottom: 6pt;
  margin-left: 0.25in;
  text-align: justify;
  text-indent: 0in;
  font-size: 1rem;
  font-family: "Calibri", sans-serif;
  font-weight: normal;
}

.terms h3 {
  margin-top: 6pt;
  margin-right: 0in;
  margin-bottom: 6pt;
  margin-left: 0.6in;
  text-align: justify;
  text-indent: 0in;
  font-size: 1rem;
  font-family: "Calibri", sans-serif;
  font-weight: normal;
}
