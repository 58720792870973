body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a2730;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-row {
  margin-bottom: 15px;
}

.error {
  color: red;
  font-size: 12px;
}

div,
nav {
  box-sizing: border-box;
}

img {
  width: 100%;
}

input {
  color: "white";
}

a {
  color: white;
}

.page-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.MuiInputBase-input {
  color: white !important;
}

.MuiInputLabel-formControl {
  color: white !important;
}

.MuiPaper-root {
  background: #424a69 !important;
  color: white !important;
}
